.edit-premium-container {
  background: whitesmoke;
  min-height: 100vh;
  position: relative;
}
.plan-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
  row-gap: 40px;
  padding-block: 20px;
}
.small-palan-container {
  max-width:  28%;
  overflow: hidden;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding-block: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
.small-image-div {
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  height: 150px;
}

.small-image-div img {
  width: 100%;
  object-fit: cover;
}
.plan-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 80%;
  padding-bottom: 20px;
  gap: 2px;
  margin-top: 20px;
}
.plan-amount-div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  border-bottom: 1px solid rgba(0,0,0,0.5);
  padding-bottom: 15px;
}
.plan-amount-div h1{
  font-size: 2vw;
}
.plan-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.discount-div {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 25px;
  justify-content: center;
  margin-top: 20px;
}

.discount-div h2 {
  display: flex;
  font-size: 1.3vw;
  opacity: 0.8;
}
.discount-div p {
  font-size: 1.3vw;
}
.discount-icon {
  font-size: 1.5rem;
  color: rgb(219, 30, 96);
}
.btn-plan-edit {
  margin-top: 30px;
  width: 120px;
}
.save-plan-div{
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;

}

.btn-plan-back{
width: 120px;
background: var(--blueColor);

}
.edit-plan-input{
  width: 60px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.5);
  padding-left: 5px;
}
.left-header {
  min-height: 100vh;
  background: #10111f;
  position: fixed;
  color: white;
  transition: all 0.3s;
  overflow: hidden;
  white-space: nowrap;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
.faq-content {
    width: 100%;
  overflow: hidden;
  height: 0px;
  transition: all 0.3s;
}
.faq-inner-content {
    width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-inline: 15px;

  padding-block: 10px;
}

.faq-title:hover{
  background: rgb(52, 52, 52);
}
.faq-title h2 {
  color: white;
  font-weight: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
 
}
.faq-title h2 a{
  color: white;
  text-decoration: none;
}
.faq-title p {
  color: var(--textColor-2);
  font-size: 1rem;
  font-weight: 400;
}
.pending {
  padding: 4px;
  color: white;
  text-decoration: none;
  padding-left: 44px;
}
.pending:hover {
  background: rgb(52, 52, 52);
}
.active:hover {
    background: rgb(52, 52, 52);
  }
.active {
  padding: 4px;
  color: rgb(19, 170, 100);
  text-decoration: none;
  padding-left: 44px;
}

.menu-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}
.title-menu{
    display: flex;
    width: 100%;
    padding-inline: 10px;
    justify-content: space-between;
    align-items: center;
    padding-block: 20px;
}
.title-menu svg{
    font-size: 1.8rem;
    cursor: pointer;
}
.title-menu h4{
    font-weight: normal;
    font-size: 20px;
}

/* Upper Header  */
nav{
  width: 100%;

  z-index: 500;
  top: 0px;
  background:white;
border-bottom: 1px solid rgb(241, 240, 240);
}
.nav-container{
  display: flex;
  justify-content: flex-end;
  padding-block: 10px;
}
.nav-container svg{
  font-size: 1.5rem;
  cursor: pointer;
  
}
.nav-container svg:hover{
  color:#FF416C;
}
.profile-div{
display: flex;
gap: 20px;
align-items: center;
}

.admin-name{
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #10111f;
  color: white;
  padding-inline: 10px;
  height: 40px;
  border-radius: 10px;
}
.profile-img-div{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.profile-img-div img{
width: 100%;
height: 100%;
object-fit: cover;
}
 
  

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}
:root{
    --btnColor:linear-gradient(84deg, #FF416C 50.21%, #FF416C 50.22%, #FF4B2B 112.44%);
    --bgColor:radial-gradient( circle farthest-corner at 10% 20%,  rgba(234,249,249,0.67) 0.1%, rgba(239,249,251,0.63) 90.1% );
   --bgWhite:#fff;
   --blueColor: #10111f;
   
}
.container{
    max-width: 1180px;
    margin-inline: auto;
    padding-inline: 20px;
    overflow: hidden;
}
.container-2{
    max-width: 1180px;
    margin-left: 250px;
    padding-inline: 20px;
    overflow: hidden;
    position: relative;
   
}

.App{
    width: 100%;
}
.overlay{
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    min-height: 100vh;
}
.btn-submit{
    width: 40%;
    height: 35px;
    border: none;
    border-radius: 5px;
    background: var(--btnColor);
    color: white;
    font-size: 17px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-container {
  background: whitesmoke;
  min-height: 100vh;
}
.live-chart-div {
  background: var(--blueColor);
  margin-top: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding-block: 20px;
  display: flex;
  justify-content: space-between;
}
.home-top-div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background: var(--blueColor);
  border-radius: 10px;
  padding-inline: 30px;
  padding-block: 40px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}
.home-top-box {
  width: 16%;
  height: 150px;
  background: linear-gradient(84deg, #ff416c 50.21%, #ff4b2b 112.44%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  gap: 15px;
}
.home-top-box svg {
  font-size: 2.5rem;
  color: white;
}
.home-top-large-box {
  width: 25%;
  background: white;
}
.home-top-box p{
  color: white;
  opacity: 0.8;
}

.resgitration-div {
  color: white;
  padding-right: 10px;
  justify-content: center;
  position: relative;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.today-registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.today-registration svg {
  font-size: 2rem;
}
.resgitration-analysis {
  display: flex;
  gap: 25px;
  width: 45%;
  justify-content: space-between;
  margin-top: 20px;
}
.vertical-heading {
  background: linear-gradient(
    87deg,
    #19dae1 8.52%,
    #36e597 64.54%,
    #19dae1 104.15%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.horizontal-heading {
  background: linear-gradient(
    90deg,
    #19dae1 0%,
    #36e597 50%,
    #19dae1 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.currect-active-user{
  align-items: center;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
  height: 33%;
  justify-content: center;
  padding-block: 10px;
  padding-inline: 10px;
  width: 99%;
}

.click-effect {
  cursor: pointer;
  transition: transform 0.3s;
}

.click-effect:hover {
  transform: scale(1.05);
}

/* Pie Chart */
.pie-chart{
  height: 400px;
  padding-inline: 20px;
  display: flex;
  gap: 50px;
  align-items: center;

}
.gender-ratio-container{
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-inline: 100px;
  padding-block: 20px;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  margin: auto;
}
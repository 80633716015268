.input-box{
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background-color: whitesmoke;
    padding: 10px;
   
}
.submit-btn-box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    padding-bottom: 50px;
}
.btn-open-div{
    display: flex;
    align-items: end;
    text-align: end;
    align-items: end;
    justify-content: end;
    margin-right: 30px;
    margin-top: 9px;
}

.confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .button-delete {
    margin: 5px;
    padding: 10px;
    border: none;
    cursor: pointer;
  border-radius: 5px;
  border: none;
  background: var(--btnColor);
  color: white;
  cursor: pointer;
  }